import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  ArtworkConfigLocal,
  ArtworkConfigPopulated,
  JWTTokenDecoded,
} from "../../models";
import { Props } from "./models";
import {
  ArtworksWrapper,
  HomeContainer,
  PageTitle,
  SingleArtwork,
  SingleArtworkAuthor,
  SingleArtworkTitle,
} from "./styles";
import jwt_decode from "jwt-decode";
import { AuthService } from "../../services/auth";
import { Subscription } from "rxjs";
import { ArtworksService } from "../../services/artworks";
import { StyledTextButton } from "../styles";
import { ArtworkTitleAndYearWrapper } from "../artwork/styles";

export function Home(props: Props) {
  const navigate = useNavigate();
  const componentSubs = new Subscription();

  const [artworkIdAllowedToChange, setArtworkIdAllowedToChange] = useState<
    number | null
  >(null);

  useEffect(() => {
    const token = AuthService.getToken();
    if (props.isAuthenticated && token) {
      const tokenDecoded: JWTTokenDecoded = jwt_decode(token);
      setArtworkIdAllowedToChange(+tokenDecoded.sub);
    } else {
      setArtworkIdAllowedToChange(null);
    }
  }, [props.isAuthenticated]);

  const changeArtworkStatus = (newStatus: boolean) => {
    componentSubs.add(
      ArtworksService.changeArtworkStatus(newStatus).subscribe({
        next: () => {
          props.loadData();
        },
        error: (errorMessage: string) => {
          console.error("Unable to change artwork status", errorMessage);
        },
      })
    );
  };

  return (
    <HomeContainer>
      {/* <PageTitle>Artworks</PageTitle> */}
      <ArtworksWrapper>
        {props.artworksConfig.map((artwork: ArtworkConfigPopulated) => {
          return (
            <SingleArtwork key={uuid()}>
              <div
                onClick={() => {
                  return navigate(`/artwork/${artwork.id}`);
                }}
              >
                {artwork.animated ? (
                  <video
                    id={`${artwork.id}`}
                    muted
                    autoPlay
                    loop
                    playsInline
                    src={artwork.videoUrl}
                    poster={artwork.imageUrl}
                    width="360"
                  >
                    Sorry, your browser doesn't support embedded videos.
                  </video>
                ) : (
                  <img src={artwork.imageUrl} width="360" />
                )}
              </div>
              <div>
                <ArtworkTitleAndYearWrapper>
                  <SingleArtworkTitle>{artwork.title}, </SingleArtworkTitle>
                  {artwork.year}
                </ArtworkTitleAndYearWrapper>{" "}
                <SingleArtworkAuthor>{artwork.author}</SingleArtworkAuthor>
              </div>
              <div>
                {artwork.id === artworkIdAllowedToChange ? (
                  <StyledTextButton
                    onClick={() => changeArtworkStatus(!artwork.animated)}
                  >
                    {artwork.animated ? "Deactivate" : "Activate"}
                  </StyledTextButton>
                ) : null}
              </div>
            </SingleArtwork>
          );
        })}
      </ArtworksWrapper>
    </HomeContainer>
  );
}
